<template lang="pug">
  div
    TheHeader
    p.not_found {{ $t('common.notFound') }}
</template>

<script>
import TheHeader from '@/components/TheHeader';

export default {
  components: { TheHeader },
  data() {
    return { updatedAt: '2019-09-24' };
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .not_found { margin: 50vh auto 0; }
</style>
